import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Root from './routes/root'
// import Construction from './routes/construction'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <Construction />,
  // },
  {
    path: '/',
    element: <Root />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdgjjQkAAAAACYBTVIzBO29uzM7EJ6Hm_PK4lCl"
      language="pt-BR"
    >
      <RouterProvider router={router} />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
