/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from 'react'
import { Modal, Row } from 'react-bootstrap'
import imgTeachers from '../assets/images/professores/index'

interface Teacher {
  name: string
  mainDescription: string | React.ReactElement
  position: string
  picture?: string
  social?: {
    icon: string
    link: string
  }[]
}

/* eslint-disable jsx-a11y/anchor-has-content */
export function Teachers() {
  const [show, setShow] = useState(false)

  const coordinators: Teacher[] = [
    {
      name: 'Marcel Eduardo Viotto Romero',
      mainDescription: (
        <>
          <p>
            Graduado (2002) e Mestre em Engenharia Elétrica pela Universidade
            Estadual Paulista Júlio de Mesquita Filho (2009), especialista em
            Proteção de Sistemas Elétricos pela Universidade Federal de Itajubá
            (2010) e MBA em Gestão Empresarial pela Fundação Eurípides de
            Marília (2004). Atuou em cargos de liderança na Companhia Paranaense
            de Energia (COPEL) nas áreas de operação e manutenção.
          </p>
          <p>
            Atualmente atua na área de engenharia e implantação desta mesma
            empresa. Tem ainda experiência como professor em cursos de graduação
            e pós-graduação. É coordenador do curso de pós-graduação em Proteção
            de Sistemas Elétricos pela Universidade Filadelfia de Londrina.
          </p>
        </>
      ),
      position: 'Coordenador',
      picture: imgTeachers.marcel,
      social: [
        {
          icon: 'bi-file-text',
          link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4735352T8&tokenCaptchar=03AFY_a8W_oXYusxmYKvYuyIVkDHHd6Dtb0YxsvJLheJCjldh1UXoQJXhQY52KyG9J54JZmBwqm5DzZ8Fec_L0dSGphiJlgaM5b4wS5u8CALfxrwBXldSgXvRqQGxQ3nIX2xVQ-duELpsc913PYllkNoDU8YVwydIDlRd5eu8PudeROzggx3mlFH1fR5MFxzogh4TE8yMzRLZHMyJrTFw0-4oLwpCuX3UNh9LMQfV4zLIfx_ggggACtDihMPh6yXTY_gSBNjYkFOUM3SQllCt42oZNICWzWreh3g0aVvFJll8SYDN3vwV-xU93DhWvbvf7NA1mp7FGNGHEY4_h9Ben5qP5o1xr0RnOVnx7n5FLhN0GUXyjCSdwebcKYYVt0W9MRXLRYbHNSsnXIYDAJ5i0rN1u8iD9mq8WxMxJ5K5EWbAV-kAsdi6dbZUSuLCqjJK22NU7J7quQbKIDm0I8b_kXoSMMUt4odZSD2YRhYOWsKzAbmYxaD4f6ZsKfw-7EZin8wvomjefhLkcQ0hF_lZB_sQpsjmIQknf8PrpwfPsVrqfTNeA_7-pXV3K1mmD0D_2zrqtfEvm7AY40uayoCQyOHj3wOPEzvWxwA',
        },
      ],
    },
    {
      name: 'Márcio Alexandre Ramos Pinto',
      mainDescription: (
        <>
          <p>
            Possui graduação em Engenharia Elétrica pela União Metropolitana de
            Ensino Paranaense (2011), Especialização em Proteção de Sistemas
            Elétricos de Potência pela Universidade Cruzeiro do Sul (2013) e MBA
            em Gestão Empresarial pela Fundação Getúlio Vargas (2021). Possui
            experiência na área de Projetos Industriais, Comerciais e Prediais,
            Projetos de Redes de Distribuição Urbanas, Rurais, Subestações da
            classe 34,5KV e 138KV, Proteção de Sistemas Elétricos de Potência e
            de Planejamento de Manutenção de Redes de Distribuição. Atuou como
            Professor Assistente B - Nível I na Universidade Norte do Paraná,
            onde ministrou as disciplinas de Sistemas Elétricos de Potência I e
            II e Circuitos Elétricos I e II.
          </p>
          <p>
            É Professor das disciplinas de Curto-circuito em nível de
            pós-graduação pela DeltaSEP/Universidade Filadelfia de Londrina.
            Atua ainda como instrutor nos cursos de Curto-Circuito e Filosofia
            da Proteção de Sistemas de Média e de Alta Tensão na Companhia
            Paranaense de energia a mais de 10 anos, e como docente nos cursos
            de Projetos de Redes de Distribuição de Energia e de Curto-circuito
            na empresa Proenge Cursos desde 2017. Atualmente exerce o cargo de
            gerente da divisão de manutenção de subestações da região norte do
            Paraná na Companhia Paranaense de Energia.
          </p>
        </>
      ),
      position: 'Coordenador',
      picture: imgTeachers.marcio,
      social: [
        {
          icon: 'bi-instagram',
          link: 'https://www.instagram.com/prof.marcioalexandre/',
        },
        {
          icon: 'bi-file-text',
          link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4678797U6&tokenCaptchar=03AFY_a8WoIId2Z17OHsgrFgxyEGDbpyAQqKW8iExYnlKJeXe3VuDRE-DNsv_fGVbZwtFRqqOmKJHtTsMb-h9VJXaSY_90u9ztAK-syAsDkbbq_Hj9ScviPOs8FnsogP83YOifv3gShZL6DgSV979IF5mrbwg6hvGlqdKX-NEYmV4Sf_ct0eXEKqP3XHT-7P5qjYrd98IpYO0tW_bTfBT_NB2i3cTLfSNAkuApSQW86FqdkjmjIKfn70_lj1fe0h3AsH9YC7vCkHuc6hHe5eZOlHFcziJssObjbc2Gnkk9lsUv35lWSt0vmrnYA3YyKnkc-4k5JLaq1jJ0MhQMGS0OAzHymCXF1X47pj5M7lbqXdCGb2MHWARruMySgeeHWQ-ttsRxFUD3DspvKhuucHlMB-qeWAEd-cFf8haH7GUnhkAa-9q6wkkUbElXc_7bToMzjERhfTfs2gjT2wxlRaV8SL0mk6iwLEIegIPrkD90Q5PWhJsFNYM7rRDYhyeeDjglZqu0XKOt0SAppdtogPpRWmMRJe-iX7Zclg',
        },
      ],
    },
  ]
  const teachers: Teacher[] = [
    {
      name: 'Geraldo Kindermann',
      mainDescription: (
        <>
          <p>
            Natural de Araranguá- SC, foi Professor da Graduação e Pós-graduação
            do Departamento de Engenharia Elétrica da Universidade Federal de
            Santa Catarina (UFSC), Coordenador e Ministrante de cursos
            promovidos pela ELETROBRÁS. Ministrou vários cursos e palestras no
            país e no exterior.
          </p>
          <p>
            É autor dos livros: Aterramento Elétrico, Choque Elétrico,
            Curto-circuito, Proteção Contra Descargas Atmosféricas em Estruturas
            Edificadas, Proteção de Sistemas Elétricos de Potência volume 1,
            Proteção de Sistemas Elétricos de Potência volume 2 e Proteção de
            Sistemas Elétricos de Potência volume 3.
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.geraldoKindermann,
    },
    {
      name: 'João Ricardo M. S. De Souza',
      mainDescription: (
        <>
          <p>
            Doutor e Mestre em Engenharia Elétrica pelo PPGEE/UFMG (Programa de
            Pós-Graduação em Engenharia Elétrica da Universidade Federal de
            Minas Gerais), tendo concluído seus cursos respectivamente em 2019 e
            2012. Trabalhou como engenheiro de proteção de sistemas elétricos na
            COPEL (Companhia Paranaense de Energia) de 2005 a 2014 onde era
            responsável pelo ajuste e parametrização de relés de proteção e pela
            análise de perturbações. Trabalhou também como professor do
            Departamento de Eletroeletrônica e Computação (DELCOM) do Centro
            Federal de Educação Tecnológica de Minas Gerais (CEFET-MG) de 2014 a
            2022.
          </p>
          <p>
            Desde 2022 trabalha na UFMG, onde é atualmente professor adjunto do
            Departamento de Engenharia Elétrica da Escola de Engenharia. Possui
            vasta experiência em Sistemas Elétricos de Potência (SEP), em
            especial na área de Proteção de Sistemas Elétricos. É autor e
            coautor de diversos artigos técnicos em revistas e periódicos
            internacionais e nacionais e em anais de congressos internacionais e
            nacionais.
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.joaoRicardo,
    },
    {
      name: 'Luis Henrique Gregório',
      mainDescription: (
        <>
          <p>
            Possui o título de engenheiro (2007) pela Universidade Tecnológica
            Federal do Paraná (UTFPR) e título de técnico em eletrotécnica pelo
            Instituto Politécnico Estadual (IPE-PR). Ingressou na Companhia
            Paranaense de Energia (COPEL) em 2004 como Técnico de Projetos e
            Obras. Desde 2007 trabalha como engenheiro de proteção na área de
            manutenção da transmissão do sistema elétrico da COPEL GeT (Geração
            e Transmissão). Entre suas funções ele ajusta e comissiona todo o
            sistema de proteção, controle e automação de novas subestações de
            energia elétrica.
          </p>
          <p>
            Também tem a função de analisar ocorrências da rede através do uso
            de oscilogramas e sequenciadores de eventos e avaliar as melhorias
            nos projetos de novas subestações. Desde 2017 leciona o curso de
            proteção de transformadores para novos engenheiros da COPEL GeT. Em
            2013 concluiu Especialização em Gestão de Projetos na FAE Centro
            Universitário e em 2019 concluiu o curso de MBA em Finança e Mercado
            de Capitais na FAE Centro Universitário.
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.gregorio,
      social: [
        {
          icon: 'bi-file-text',
          link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K8917025A9&tokenCaptchar=03AFY_a8Xb5P4J-dj1etFIu2qKhFPMoZl-IxSEftPip_CosJAQ0IbieHABcJDQ2vMWWNjPLn_AQg7YB2CJbu4RuTyF3rokuRxkO4gN7K5qdBXrEIHAIPBO82iw8TSQZ5U6AbIYQzDZ6kpW4A0NdSpmXqhFL3fk_oFimPC1hw_2TXBZn2pOqTBXXJyXn6UJ0zQt-adTrfk2EAEFeczPEsf1x1o50rJK80Ualx1UXnVUT3MxgNHKSx_CFIqjACXKuSOvoBZFUw_ANnLj1VPLjQ59Mr0ZbV7Uol6l8ML4WP8EW8T9PqWpGW8tMRcZUP4ES5b5P8c430i8T9CiIFRfk3aqPasVqfDadDj-gjW8f7F05tYaI4W1FCXJMAR6TZ4qPuOAwB3ht4fBaaSjuAnkzzhT-hK595ofpxy4N1cC5JJQ5v6C87sEhw3X3ku-fAe4DgJOjAmob44PeqNvfXrvs_Zz0vgiHMgbGKGCcxiag5C1XuTekSRXTuAWq149I49nKpr06neV1iB5VL2m-TVLbkI590adtwh2R6Kr3Jwujs6HAbbDBgx9PLzVGVba2bmYlayMyeTLGj3bxqe6mLjOTymFSi2Zu09f_ISwqw',
        },
      ],
    },
    {
      name: 'Rafael do Oliveira Ribeiro',
      mainDescription: (
        <>
          <p>
            Possui graduação (2009) e mestrado (2012) em Engenharia Elétrica
            pela Universidade Estadual de Londrina (UEL). Especialista em
            Proteção de Sistemas Elétricos (2011) e Especialista em Avaliações,
            Auditoria e Perícias em Engenharia (2018). Ingressou em 2011 como
            engenheiro eletricista da Companhia Paranaense de Energia (COPEL) no
            Setor de Proteção de Média Tensão da Distribuição, onde trabalhou e
            supervisionou a área de estudos de proteção do sistema de
            distribuição (alimentadores e subestações de 13,8 kV e 34,5 kV),
            realizando o cálculo de curto-circuito, sistemas de Self Healing e
            Smart Grid, análise de eventos no sistema de distribuição
            (oscilografias), ajustes de relés/religadores e demais equipamentos
            do sistema de proteção bem como a análise de acessantes de geração
            no sistema de média tensão (PCHs, CGHs, UTEs, UFVs).
          </p>
          <p>
            Também atuou como assistente técnico em perícias judiciais em casos
            envolvendo o sistema elétrico e docente desde 2011 em cursos de
            Graduação e Pós-graduação em Engenharia Elétrica. Atualmente é o
            gerente da Divisão de Operação em Tempo Real da Copel Distribuição e
            é autor do livro Proteção do Sistema de Distribuição de Energia
            (2018).
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.rafael,
      social: [
        {
          icon: 'bi-file-text',
          link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4103630Z8&tokenCaptchar=03AFY_a8UXVOfUIVy5ol64XejT82ysyxkqUfdwlWSW7ckNdFdNBfZ2emRSWkaE3J_ZXhAsn71jPLPLhJ8sPR2nh9Y12bZryaNkAv1d0KqPH21qeNth1eygzVYzUMIGLj1g68CwU7jAgpK_86SFGjHZ4y5msCtd132467k6BCIlS0Gahko0d22thUR__UT7ckHsLpY5NovGOOC07c7pulkLMdT94X2bA6aAWtbbVukoINATi5BXJW7pUrAcjsVPpja9MG8A5INULqcPDgzGRaROCqiicLhRh2YKLvZbufSUXcugetjhSX_j42cmUCrEoHBg6-f6ULDJm8UO7pT0ImMQ1Pkh7x4QCKH9Frsi0oEFIcOswAUW9nNn4pAekFQXP1M3iTuOLbjtLmhpYRZTHzO_1yRoX7cG8AwrEDgGUKbyLYxqZUhxunfdnYsFRbDDZ6wP3j33M_bRG-m-8ulwwp2mUQ9VlZiD5FfyfWw2WL07th_L0NqB0GYCpSe10lZA9EZatZnG0zFZS0fyVTGzE7CZ5g8L1Eqk8dN9VxIuTRgNklr7e2mdgx81_pRjy-TyKeDgVP8pX4f02cI58JAK0ZmtfRIF23RpYuEB8A',
        },
      ],
    },
    // {
    //   name: 'Rodrigo Zempulski Fanucchi',
    //   mainDescription: (
    //     <>
    //       <p>
    //         Possui graduação em Engenharia Elétrica pela Universidade Estadual
    //         de Londrina (2004), mestrado e doutorado pela Universidade de São
    //         Paulo na Escola de Engenharia de São Carlos (2014 e 2020,
    //         respectivamente) nas áreas de Sistemas Elétricos de Potência e
    //         Sistemas Dinâmicos. Atualmente é gerente da divisão de
    //         direcionamento da manutenção norte e coordenador do grupo de análise
    //         de dados da Companhia Paranaense de Energia Elétrica e docente no
    //         Programa de Pós-Graduação em Bioenergia da Universidade Estadual de
    //         Londrina e em cursos de atualização e pós-graduação.
    //       </p>
    //       <p>
    //         Tem experiência com sistemas elétricos de potência, desenvolvendo
    //         abordagens com técnicas e conceitos de IA, desde de detecção de
    //         faltas de alta impedância, até roteamento de equipes em campo e
    //         recomposição de alimentadores. Vencedor do A.I. Awards de 2019 na
    //         categoria doutorado, prêmio oferecido pela DATA H. Responsável
    //         direto por projeto de pesquisa e desenvolvimento de análise de
    //         vulnerabilidades em redes elétricas utilizando conceitos de visão
    //         computacional, Deep Learning e Big Data. Atualmente faz estágio
    //         pós-doutoral na Universidade Federal de Goiás no Instituto de
    //         Informática com foco em problemas de logística.
    //       </p>
    //     </>
    //   ),
    //   position: 'Professor',
    //   picture: imgTeachers.rodrigo,
    //   social: [
    //     {
    //       icon: 'bi-file-text',
    //       link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4443836Z3&tokenCaptchar=03AFY_a8X_Norh8Q66gxdZgSPndznX8Z0nfPL9u6UzFppHBrLqtcA8F7oBPSbgeKTlW2UcL31CGC0B9RCuKbxhVttbbCNROmBftIXD09x4dzOziaNDqS7jwhTqctmaUCDXo8H3YNgvdTI5nrCTycFjkegdNzRm0rA6yxJfjye79-ibklXJA-WRrr2hj-sykp0P6gZxCcDeWKelmlHQkCoiGGA50mnqatoF3RdhQ-GAqFYvw_CJ5pxZoJx4_ZNMUZCFNY-Y43mmZyj5pm3KaHqAgu7AR_wCU_4JYQ9tZzLPBNwm2S5-7POMaUUHWepoKAwhoOfZMsaIfo6vaz3stWPJUau8la4UrwJ_6dDhHoXos8_ZbnVE4qhZV1JtjW12sMY1lt9C9ZQjjUMCj7yLAqvYnXLPW-_3dMk3SGIpJ8LSFgmxpfSjO4wJE-HJeqgBKocM0aJeTVbYYte7Wq-glNcbHHFt6U-0O-R68iviRvWwrg-N0EPi9Y7HnY_MwkSRzJl5VNbbX7NqND12KbS3LJYPv0qr1I9-ueqZjb8zRwvuUr3mopZqS2Ec63WURethDqqF0Qavxph58xBJ1-uWofEeeOjI-8oDcdPEoA',
    //     },
    //   ],
    // },
    {
      name: 'Waldemar Pereira M. Neto',
      mainDescription: (
        <>
          <p>
            Possui graduação, mestrado e doutorado, em Engenharia Elétrica pela
            Univ. Estatual Paulista “Júlio de Mesquita Filho” - UNESP, campus
            Ilha Solteira-SP, além de especialização em finanças pela FAE
            Business School, Curitiba-PR. No âmbito profissional atua como
            engenheiro de proteção, automação e controle na Companhia Paranaense
            de Energia (COPEL) desde 2012.
          </p>
          <p>
            Na área de atuação possui experiência em proteção de sistemas de
            transmissão e subtransmissão, redes e protocolos de comunicação, IEC
            61850, entre outros.
          </p>
          <p>
            Na área acadêmica possui experiencia em otimização combinatória,
            baseado em técnicas de otimização clássicas, heurísticas e
            meta-heurísticas. Neste último, principalmente, algoritmo genético,
            busca tabu, GRASP, algoritmo de colônia de formiga, entre outros.
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.waldemar,
      social: [
        {
          icon: 'bi-instagram',
          link: 'https://instagram.com/profjoaoricardoprot?igshid=YmMyMTA2M2Y=',
        },
        {
          icon: 'bi-file-text',
          link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4284660D3&tokenCaptchar=03AFY_a8UDoFOo-LgjSqQ4NjAGbR1CvOd9n5bhMCsCo3uU4HHQ7BW7Q-O1dV-Nbzp4CUVo7XqrZB7ooV0Nvzn240_tojshusPkvbeVF1w2rTEAZpxLy6l8CLhvX_ClAc79dGw2IN9qTmjlyUs6sE7c25o626IFopA86PbxI0IT0407mOeeZeb3bSPgCfq7NhRcbGWjyAymNRt3N1EHnLs0yC28Tw-gi4PZhB2WiErWluwn2JI5muRo_HgDtgZ88k7802q7OqXcYIF3E2BtsX9aRr9ZzEknz0pC6z8VSZFC7ArMIpyy1ZQlyq7h2FjsY0J_U-Uxdp_SxHl92e-ht_0K5yWQD-pI6MOcEMmojwmnnpFfMmkwuPBMP-KQKhliCrGFul54tfYAIhP3VFAlpyINcU6WcdRjGkSV_fMC5sQaR7T6yEw3K4dcwAEPlDFzXhDggdXdn6EzwOFMY2Lpw3ejJFioCTNDicx1nPEcSe2pgcSxMxWO3YsGYh-P1Qu6SQ0b7fmxp_yFPNHHLkc-nTUVv08nvn8iC3PCDJPfASL8IdZyqfu_XdO0E6naAVfxmmFc7ykkqxsWScA4Y4El6wUL-Edi0akh9Fk39Q',
        },
      ],
    },
    {
      name: 'Willian Carlos de Oliveira',
      mainDescription: (
        <>
          <p>
            Graduado (2002) pela Universidade Estadual Paulista Júlio de
            Mesquita Filho - Unesp de Ilha Solteira. Mestrado Profissional em
            Máquinas Elétricas Girantes (2008) pela Universidade Federal de
            Santa Catarina - UFSC.
          </p>
          <p>
            Especialista em Geradores Síncronos na área de projetos, fabricação
            e O&M. Atualmente ocupa o cargo de Engenheiro Especialista e
            Coordenador de Engenharia na Engie Brasil
          </p>
          <p>
            Energia, com sede em Florianópolis. Tem ainda experiência como
            professor em cursos de pós-graduação.
          </p>
        </>
      ),
      position: 'Professor',
      picture: imgTeachers.willian,
      social: [
        // {
        //   icon: 'bi-instagram',
        //   link: 'https://instagram.com/profjoaoricardoprot?igshid=YmMyMTA2M2Y=',
        // },
        // {
        //   icon: 'bi-file-text',
        //   link: 'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4284660D3&tokenCaptchar=03AFY_a8UDoFOo-LgjSqQ4NjAGbR1CvOd9n5bhMCsCo3uU4HHQ7BW7Q-O1dV-Nbzp4CUVo7XqrZB7ooV0Nvzn240_tojshusPkvbeVF1w2rTEAZpxLy6l8CLhvX_ClAc79dGw2IN9qTmjlyUs6sE7c25o626IFopA86PbxI0IT0407mOeeZeb3bSPgCfq7NhRcbGWjyAymNRt3N1EHnLs0yC28Tw-gi4PZhB2WiErWluwn2JI5muRo_HgDtgZ88k7802q7OqXcYIF3E2BtsX9aRr9ZzEknz0pC6z8VSZFC7ArMIpyy1ZQlyq7h2FjsY0J_U-Uxdp_SxHl92e-ht_0K5yWQD-pI6MOcEMmojwmnnpFfMmkwuPBMP-KQKhliCrGFul54tfYAIhP3VFAlpyINcU6WcdRjGkSV_fMC5sQaR7T6yEw3K4dcwAEPlDFzXhDggdXdn6EzwOFMY2Lpw3ejJFioCTNDicx1nPEcSe2pgcSxMxWO3YsGYh-P1Qu6SQ0b7fmxp_yFPNHHLkc-nTUVv08nvn8iC3PCDJPfASL8IdZyqfu_XdO0E6naAVfxmmFc7ykkqxsWScA4Y4El6wUL-Edi0akh9Fk39Q',
        // },
      ],
    },
  ]

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [currentTeacher, updateCurrentTeacher] = useState<Teacher | undefined>()

  const seeTeacher = (teacher: Teacher) => {
    updateCurrentTeacher(teacher)
    handleShow()
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-12">
          <h2 className="mb-5 text-center">
            Corpo <u className="text-info">Docente</u>
          </h2>
        </div>

        {coordinators.map((teacher) => {
          return (
            <div key={teacher.name} className="col-lg-5 col-12 mt-3">
              <div
                className="speakers-thumb"
                onClick={() => seeTeacher(teacher)}
              >
                <img
                  src={teacher.picture}
                  className="img-fluid speakers-image"
                  alt=""
                />

                <div className="speakers-info">
                  <h5 className="speakers-title mb-0">{teacher.name}</h5>

                  <p className="speakers-text mb-0">{teacher.position}</p>

                  {teacher.social && (
                    <ul className="social-icon">
                      {teacher.social.map((social, i) => {
                        return (
                          <li key={i}>
                            <a
                              href={social.link}
                              target="_blank"
                              className={`social-icon-link ${social.icon}`}
                              rel="noreferrer"
                            ></a>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )
        })}

        <div className="col-lg-12 col-12">
          <h4 className="mb-3 mt-5 text-center">Professores Convidados</h4>
        </div>

        <div className="col-lg-12 col-12">
          <div className="row d-flex justify-content-center">
            {teachers.map((teacher, i) => {
              return (
                <div key={i} className="col-lg-4 col-md-6 col-12">
                  <div
                    className="speakers-thumb speakers-teachers speakers-thumb-small"
                    onClick={() => seeTeacher(teacher)}
                  >
                    {teacher.picture ? (
                      <img
                        src={teacher.picture}
                        className="img-fluid speakers-image"
                        alt=""
                      />
                    ) : (
                      <img
                        src="images/avatar/portrait-good-looking-brunette-young-asian-woman.jpg"
                        className="img-fluid speakers-image"
                        alt=""
                      />
                    )}

                    <div className="speakers-info">
                      <p className="speakers-title mb-0">{teacher.name}</p>

                      <p className="speakers-text mb-0">{teacher.position}</p>

                      {teacher.social && (
                        <ul className="social-icon">
                          {teacher.social.map((social, i) => {
                            return (
                              <li key={i}>
                                <a
                                  href={social.link}
                                  target="_blank"
                                  className={`social-icon-link ${social.icon}`}
                                  rel="noreferrer"
                                ></a>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentTeacher && (
                <>
                  <b>{currentTeacher.name}</b> -{' '}
                  <small>{currentTeacher.position}</small>
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="col-lg-3 col-12">
                <img
                  src={currentTeacher?.picture}
                  className="img-fluid rounded speakers-image"
                  alt=""
                />
                {currentTeacher?.social && (
                  <ul className="social-icon text-center">
                    {currentTeacher.social.map((social, i) => {
                      return (
                        <li key={i}>
                          <a
                            href={social.link}
                            target="_blank"
                            className={`social-icon-link social-icon-modal ${social.icon}`}
                            rel="noreferrer"
                          ></a>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
              <div
                className={`${
                  currentTeacher?.picture ? 'col-lg-9' : 'col-lg-12'
                } col-12`}
              >
                {currentTeacher && (
                  <>
                    <p className="text-justify">
                      {currentTeacher.mainDescription}
                    </p>
                  </>
                )}
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}
