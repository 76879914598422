import VideoBanner from '../assets/videoBanner';
export function Banner () {
    return (
        <>
            <div className="container">
                <div className="row">

                    <div className="col-lg-5 col-12 m-auto">
                        <div className="hero-text">

                            <h2 className="text-white mb-4"><u className="text-info">PÓS-GRADUAÇÃO</u><br /> PROTEÇÃO DE SISTEMAS ELÉTRICOS DE POTÊNCIA</h2>

                            <div className="d-flex justify-content-center align-items-center">
                                <span className="date-text">Aulas ao vivo</span>

                                <span className="location-text">Inscrições abertas</span>
                            </div>

                            <a href="#section_2" className="custom-link bi-arrow-down arrow-icon"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-wrap">
                <VideoBanner />
            </div>
        </>
    )
}