import pexelsVideo from './videos/pexels-pavel-danilyuk-8716790.mp4'

const VideoBanner = () => {

  return (
    <video className="custom-video" autoPlay muted loop controls={undefined}>
      <source src={pexelsVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

export default VideoBanner
