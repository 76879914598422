interface FAQ {
  question: string
  answer: string
}

export function Faqs() {
  const faqs: FAQ[] = [
    {
      question: 'Há disponibilização de material didático?',
      answer:
        'Sim, todos os materiais são disponibilizados na forma de arquivos digitais pelos professores.',
    },
    {
      question:
        'Quando o aluno é reprovado em uma disciplina, é possível finalizar o curso? Ele consegue refazer a matéria? ',
      answer:
        'Sim, o aluno poderá realizar novamente a disciplina em outra turma.',
    },
    {
      question: 'Onde encontro o conteúdo programático do curso?',
      answer:
        'No site da Delta SEP está disponibilizado todo o conteúdo programático do curso.',
    },
    {
      question: 'Como funciona o sistema de avaliação?',
      answer:
        'As avaliações são aplicadas na forma individual ou em grupo a critério de cada professore que podem ter formato de prova ou trabalho.',
    },
    {
      question:
        'Há obrigatoriedade de entregar um trabalho de conclusão de curso?',
      answer:
        'Não, há uma portaria do MEC que determina que o trabalho de conclusão de curso em pós-graduação não é mais uma exigência, no entanto, pode ser solicitado no final do curso, um artigo científico para submissão em órgãos de educação.',
    },
    {
      question: 'O curso é reconhecido pelo MEC?',
      answer:
        'Sim, a instituição certificadora é a Unifil (Centro Universitário Filadélfia de Londrina)',
    },
    {
      question: 'Quais são os documentos necessários para efetuar a matrícula?',
      answer:
        'Cópia de RG, CPF, diploma de graduação, comprovante de endereço, certidão de nascimento ou casamento e uma foto 3x4.',
    },
    {
      question: 'Quem é o público-alvo?',
      answer:
        'O curso é voltado para portadores de Diploma de Curso Superior em Engenharia Elétrica, ou áreas afins, alunos matriculados no último semestre dos cursos nas áreas de engenharia e tecnologia, profissionais graduados com conhecimentos técnicos que atuam na indústria e empresas de energia elétrica que desejam atualizar e aumentar seus conhecimentos, possibilitando seu ingresso no setor de Sistemas de Energia Elétrica.',
    },
    {
      question: 'Existe quantidade mínima de aluno para viabilizar o curso?',
      answer: 'Sim, 20 alunos',
    },
    {
      question: 'O curso é feito totalmente à distância?',
      answer:
        'Sim, porém, existe a possibilidade de abertura de turmas presenciais conforme demanda.',
    },
    {
      question: 'Como são as aulas?',
      answer:
        'As aulas são realizadas pela plataforma Zoom, aos sábados das 08:30h às 17:30h com uma hora de intervalo para almoço com possibilidade de ajustes diretamente com o professor da disciplina.',
    },
    {
      question: 'As aulas ficam disponíveis para o aluno assistir novamente?',
      answer:
        'Sim, as aulas estarão disponíveis para que o aluno possa assistir novamente durante 100 dias após a realização da aula.',
    },
    {
      question: 'Qual a carga horária do curso?',
      answer: '360 horas',
    },
  ]
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-12 text-center mx-auto mb-5">
          <h2>
            Perguntas <u className="text-info">Frequentes</u>
          </h2>
        </div>

        <div className="col-12 mb-5 mb-lg-0">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {faqs.map((faq, i) => {
              return (
                <div key={i} className="accordion-item">
                  <h2 className="accordion-header" id={`flush-heading${i}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-${i}`}
                      aria-expanded="false"
                      aria-controls={`flush-${i}`}
                    >
                      <h5 className="text-center">{faq.question}</h5>
                    </button>
                  </h2>
                  <div
                    id={`flush-${i}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`flush-heading${i}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
