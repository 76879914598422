export function AboutUs() {
  const handleClickScroll = () => {
    const element = document.getElementById('aboutUsContent')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-12">
          <h2 className="mb-4">
            Nossa <u className="text-info">História</u>
          </h2>
        </div>

        <div className="col-lg-6 col-12">
          <h4 className="mt-2 mb-4">
            Experiência de mais de 10 anos formando profissionais
          </h4>

          <p>
            A Delta SEP é uma empresa de ensino de pós-graduação que atua na
            área de sistemas elétricos de potência da engenharia elétrica.
          </p>

          <a
            className="custom-btn custom-border-btn btn custom-link mt-3 me-3"
            data-bs-toggle="collapse"
            href="#aboutUsContent"
            role="button"
            aria-expanded="false"
            aria-controls="aboutUsContent"
            onClick={handleClickScroll}
          >
            Saiba Mais
          </a>

          <a className="custom-btn btn custom-link mt-3" href="#section_4">
            Faça Sua Pré-matrícula
          </a>
        </div>

        <div className="col-lg-6 col-12 mt-5 mt-lg-0">
          <h5>
            A idealização ocorreu em 2009, com o objetivo de focar em aulas que
            formassem profissionais e ao mesmo tempo lhe dessem uma visão
            prática da atividade em proteção de sistemas elétricos.
          </h5>

          <div className="avatar-group border-top py-5 mt-5">
            <p className="d-inline">200+ alunos participantes</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="collapse" id="aboutUsContent">
          <div className="card card-body">
            <h6 className="mb-3">QUEM SOMOS</h6>
            <p>
              A Delta SEP é uma empresa de ensino de pós-graduação que atua na
              área de sistemas elétricos de potência da engenharia elétrica. A
              idéia de atuar nessa área de formação ocorreu 2009, com o objetivo
              de focar em aulas que formassem profissionais e ao mesmo tempo lhe
              dessem uma visão prática da atividade em proteção de sistemas
              elétricos, através de parcerias ao longo dos anos com turmas
              formadas em vários municípios nos estados de São Paulo e Paraná.
              Nestes anos, foram mais de 200 alunos participantes.
            </p>
            <h6 className="mb-3">VISÃO</h6>
            <p className="mb-5">
              Ter seus cursos reconhecidos pela qualidade e capacidade de formar
              profissionais com conhecimento necessário à estruturar soluções de
              problemas complexos.
            </p>
            <h6 className="mb-3">MISSÃO</h6>
            <p>
              Formar profissionais engajados com as atividades na área de
              sistemas de potência, criando cenários favoráveis na busca de
              soluções de problemas complexos através da inovação, técnicas e
              conhecimento tecnológico com responsabilidade, respeito e
              integridade.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
