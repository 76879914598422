import { useState } from 'react'
import { FormCheck, FormGroup, FormLabel, Toast } from 'react-bootstrap'
// import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import emailjs from '@emailjs/browser'

interface FormData {
  name: string
  phone: string
  email: string
  contact: string
  message: string
}

/* eslint-disable jsx-a11y/no-redundant-roles */
export function Form() {
  const emailData = {
    service_id: 'service_en2md4j',
    template_id: 'template_3txmhsr',
    public_key: '-cDyVny8xQbmg1ZpJ',
  }
  // const { executeRecaptcha } = useGoogleReCaptcha()
  // const [token, setToken] = useState()
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const successMessage = (
    <>
      <b>Pronto</b>, você deu o primeiro passo, daqui para frente é com a gente,{' '}
      <b>entraremos em contato em breve!</b>
    </>
  )
  const errorMessage = (
    <>
      É necessário preencher os campos <b>Nome</b> e opcionalmente{' '}
      <b>Telefone</b> ou <b>E-mail</b>
    </>
  )
  const errorSendMessage = (
    <>
      Houve um erro ao enviar os seus dados. Por favor, tente novamente mais
      tarde!
    </>
  )
  // const errorCaptchaMessage = (
  //   <>Você precisa resolver o captcha para prosseguir!</>
  // )
  const [currentErrorMessage, setCurrentErrorMessage] = useState(errorMessage)

  // const onVerify = useCallback((token: any) => {
  //   setToken(token)
  // }, [])

  const defaultFormData = {
    name: '',
    phone: '',
    email: '',
    contact: 'Whatsapp',
    message: '',
  }
  const [formData, updateFormData] = useState<FormData>(defaultFormData)

  const handleOnChange = (formData: FormData) => {
    updateFormData(formData)
  }

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    // if (!executeRecaptcha) {
    //   return
    // }
    // const result = await executeRecaptcha('register');
    // setToken(result);
    // if (!token) {
    //   setCurrentErrorMessage(errorCaptchaMessage)
    //   setShowError(true)
    //   setTimeout(() => setShowError(false), 10000)
    //   return
    // }

    if (!formData.name || (!formData.email && !formData.phone)) {
      setCurrentErrorMessage(errorMessage)
      setShowError(true)
      setTimeout(() => setShowError(false), 10000)
      return
    }

    emailjs
      .send(
        emailData.service_id,
        emailData.template_id,
        {
          ...formData,
        },
        emailData.public_key
      )
      .then((res) => {
        updateFormData(defaultFormData)
        setShowSuccess(true)
        setTimeout(() => setShowSuccess(false), 10000)
        // setRefreshReCaptcha((r) => !r)
      })
      .catch((err) => {
        setCurrentErrorMessage(errorSendMessage)
        setShowError(true)
        setTimeout(() => setShowError(false), 10000)
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-12 mx-auto">
          <form
            className="custom-form contact-form bg-white shadow-lg"
            action="#"
            method="post"
            role="form"
          >
            {/* <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            /> */}
            <h2>Pré-matrícula!</h2>

            <div className="row">
              <div className="col-12">
                <div aria-live="polite" aria-atomic="true" className="pb-3">
                  <Toast
                    show={showSuccess}
                    bg={'success'}
                    className="toast-message"
                  >
                    <Toast.Body className={'text-white'}>
                      {successMessage}
                    </Toast.Body>
                  </Toast>
                </div>
                <div aria-live="polite" aria-atomic="true" className="pb-3">
                  <Toast
                    show={showError}
                    bg={'danger'}
                    className="toast-message"
                  >
                    <Toast.Body className={'text-white'}>
                      {currentErrorMessage}
                    </Toast.Body>
                  </Toast>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  placeholder="Nome"
                  required
                  value={formData.name}
                  onChange={(e): void => {
                    handleOnChange({
                      ...formData,
                      name: e.target.value,
                    })
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <input
                  type="email"
                  name="email"
                  id="email"
                  pattern="[^ @]*@[^ @]*"
                  className="form-control"
                  placeholder="Email"
                  required
                  value={formData.email}
                  onChange={(e): void => {
                    handleOnChange({
                      ...formData,
                      email: e.target.value,
                    })
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="Telefone"
                  value={formData.phone}
                  onChange={(e): void => {
                    handleOnChange({
                      ...formData,
                      phone: e.target.value,
                    })
                  }}
                />
              </div>

              <div className="col-12">
                <FormGroup className="mb-3" controlId="contact" key="contact">
                  <FormLabel>
                    Entre em contato através
                    do:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </FormLabel>
                  <FormCheck
                    inline
                    type={'radio'}
                    id={`contact`}
                    name={`contact`}
                    value={'Telefone'}
                    label={`Telefone`}
                    checked={formData.contact === 'Telefone'}
                    onChange={(e): void => {
                      handleOnChange({
                        ...formData,
                        contact: e.target.value,
                      })
                    }}
                  />
                  <FormCheck
                    inline
                    type={'radio'}
                    id={`contact`}
                    name={`contact`}
                    value={'E-mail'}
                    label={`E-mail`}
                    checked={formData.contact === 'E-mail'}
                    onChange={(e): void => {
                      handleOnChange({
                        ...formData,
                        contact: e.target.value,
                      })
                    }}
                  />
                  <FormCheck
                    inline
                    type={'radio'}
                    id={`contact`}
                    name={`contact`}
                    value={'Whatsapp'}
                    label={`Whatsapp`}
                    checked={formData.contact === 'Whatsapp'}
                    onChange={(e): void => {
                      handleOnChange({
                        ...formData,
                        contact: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
              </div>

              <div className="col-12">
                <textarea
                  className="form-control"
                  rows={5}
                  id="message"
                  name="message"
                  placeholder="Conte porque se interessou na Pós da Delta SEP"
                  value={formData.message}
                  onChange={(e): void => {
                    handleOnChange({
                      ...formData,
                      message: e.target.value,
                    })
                  }}
                ></textarea>
              </div>

              <div className="col-12">
                <button
                  onClick={handleOnSubmit}
                  type="submit"
                  className="form-control"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
