/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from 'react'
import {
  AboutUs,
  Header,
  Banner,
  Courses,
  Teachers,
  MidBanner,
  Footer,
  Faqs,
  Form,
  UnifilBanner,
} from '../components'

export default function Root() {
  const [floatingButtonsHidden, setFloatingButtonsVisibility] = useState(true)
  const listenToScroll = () => {
    let heightToHideFrom = 500
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToHideFrom) {
      floatingButtonsHidden && // to limit setting state only the first time
        setFloatingButtonsVisibility(false)
    } else {
      setFloatingButtonsVisibility(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />

      <main>
        <div className="hero" id="section_1">
          <Banner />
        </div>

        <div className="about section-padding" id="section_2">
          <AboutUs />
        </div>

        <div className="call-to-action call-to-action-unifil section-padding">
          <UnifilBanner />
        </div>

        <div className="speakers section-padding" id="section_3">
          <Teachers />
        </div>

        <div className="schedule section-padding" id="section_4">
          <Courses />
        </div>

        <div className="call-to-action section-padding">
          <MidBanner />
        </div>

        <div className="pricing section-padding" id="section_5">
          <Faqs />
        </div>

        <div className="contact section-padding" id="section_6">
          <Form />
        </div>
      </main>

      <Footer />

      {!floatingButtonsHidden && (
        <>
          <a
            href="#section_1"
            className="bi-arrow-up custom-link floating-rollup"
          ></a>
          <a
            href="https://api.whatsapp.com/send/?phone=5543991387608&text&type=phone_number&app_absent=0"
            target="_blank"
            className="bi-whatsapp floating-contact"
            rel="noreferrer"
          ></a>
        </>
      )}
    </>
  )
}
