import geraldoKindermann from './geraldoKindermann.jpg'
import gregorio from './gregorio.jpeg'
import joaoRicardo from './joaoRicardo.gif'
import marcel from './marcel.jpg'
import marcio from './marcio.jpg'
import rodrigo from './rodrigo.jpeg'
import waldemar from './waldemar.jpg'
import rafael from './rafael.jpeg'
import willian from './willian.jpg'

const imgTeachers = {
    geraldoKindermann: geraldoKindermann,
    gregorio: gregorio,
    joaoRicardo: joaoRicardo,
    marcel: marcel,
    marcio: marcio,
    rafael: rafael,
    rodrigo: rodrigo,
    waldemar: waldemar,
    willian: willian,
}

export default imgTeachers;