import { useState } from 'react'
interface Course {
  name: string
  topics: string[]
}

export function Courses() {
  const courses: Course[] = [
    {
      name: 'Análise de Sistemas Elétricos e Filosofia de Curto Circuito',
      topics: [
        'Circuitos em Corrente Alternada (fasores e polaridade);',
        'Diagrama Unifilar de um Sistema Elétrico;',
        'Diagrama de Impedância de um Sistema Elétrico;',
        'Valores em por Unidade;',
        'Representação em p.u. por Fase de um Sistema de Potência Completo;',
        'Ocorrências dos tipos de Curtos Circuitos nos Sistemas de Energia Elétrica;',
        'Operações com números Complexos;',
        'Causas de Faltas na Rede Elétrica;',
        'Consequências das Faltas no Sistema Elétrico;',
        'Tipos de Faltas;',
        'Curtos circuitos nos Sistemas de Energia Elétrica;',
        'Ocorrências dos tipos de Curtos Circuitos nos Sistemas de Energia Elétrica;',
        'Correntes Assimétricas.',
      ],
    },
    {
      name: 'Componentes Simétricas',
      topics: [
        'Conceitos de Componentes Simétricas:',
        'Características das Componentes Simétricas;',
        'Representação de Redes por seus Diagramas Sequenciais;',
        'Representação de Geradores Síncronos;',
        'Representação de Transformadores;',
        'Representação de Linhas de Transmissão.',
      ],
    },
    {
      name: 'Cálculo de Curto Circuito',
      topics: [
        'Curto circuito no Gerador Síncrono;',
        'Curto circuito no Sistema Elétrico;',
        'Curto circuito no Sistema de Distribuição de Energia Elétrica.',
      ],
    },
    {
      name: 'Transitórios Eletromagnéticos',
      topics: [
        'Sistemas Computacionais para análise de transitórios eletromagnéticos(ATP e ATPDraw);',
        'Modelagem de redes e componentes Simulações (energização desenergização de LTs, transformadores, capacitores, curto-circuito em LTs, etc). Interpretação e análises de resultados.',
      ],
    },
    {
      name: 'Máquinas Elétricas e Estabilidade do SEP',
      topics: [
        'Estabilidade em regime permanente;',
        'Dinâmica de máquinas síncronas;',
        'Sistema máquina-barra infinita;',
        'Estabilidade síncrona e estabilidade de frequência;',
      ],
    },
    {
      name: 'Transformadores de Instrumentos e Filosofia da Proteção',
      topics: [
        'Transformadores de Corrente aplicados em Sistemas de Proteção;',
        'Burden;',
        'Seleção de TC’s e Análise de Desempenho durante Faltas;',
        'Transformadores de Potencial aplicados em Sistemas de Proteção.',
        'Características dos Sistemas de Proteção;',
        'Proteção de Sobrecorrente;',
        'Proteção Direcional;',
        'Proteção Diferencial;',
        'Proteção de Distância;',
        'Teleproteção.',
      ],
    },
    {
      name: 'Proteção de Geradores',
      topics: [
        'Introdução e Problemas Potenciais;',
        'Proteção de Sobretensão;',
        'Proteção de Carga Assimétrica;',
        'Proteção Diferencial;',
        'Proteção de Terra do Estator;',
        'Proteção de Terra do Rotor;',
        'Proteção de Perda de Excitação;',
        'Proteção Voltz/Hertz;',
        'Outros Esquemas de Proteção para Geradores.',
      ],
    },
    {
      name: 'Proteção de Barras e Transformadores',
      topics: [
        'Introdução e Problemas Potenciais;',
        'Introdução e Problemas Potenciais;',
        'Introdução e Problemas Potenciais;',
        'Proteção de Falha de Disjuntor;',
        'Demais Esquemas de Proteção para Barra.',
        'Introdução e Problemas Potenciais;',
        'Características de Magnetização;',
        'Proteção Diferencial;',
        'Proteção de Sobrecorrente com Restrição de Tensão;',
        'Proteção de Terra;',
        'Proteção de Buchholtz;',
        'Demais Esquemas de Proteção para Transformadores.',
      ],
    },
    {
      name: 'Proteção de Linhas',
      topics: [
        'Classificação das Linhas e Alimentadores;',
        'Técnicas e equipamentos para Proteção de Linhas;',
        'Proteção de Distância;',
        'Zonas de Atuação;',
        'Proteção de Sobrecorrente Direcional;',
        'Esquemas de Teleproteção;',
        'Demais Esquemas de Proteção para Linhas.',
      ],
    },
    {
      name: 'Proteção de Sistemas de Distribuição de Energia Elétrica',
      topics: [
        'Sistemas Radiais Simples;',
        'Sistemas de Proteção Aplicados a Sistemas de Distribuição;',
        'Fundamentos de Coordenação;',
        'Problemas com faltas de Alta Impedância;',
        'Desafios da Geração Distribuída.',
      ],
    },
    {
      name: 'Proteção de Sistemas Industriais e Motores',
      topics: [
        'Curto circuito nas instalações elétricas;',
        'Proteção de fornos a arco;',
        'Proteção de motores;',
        'Coordenação da proteção;',
        'Demais esquemas de Proteção para Sistemas Industriais.',
      ],
    },
    {
      name: 'Proteção de Cabines',
      topics: [
        'Revisão dos Conceitos básicos de Proteção.',
        'Elos fusíveis do sistema de distribuição de energia.',
        'Elos fusíveis do sistema de distribuição de energia.',
        'Relés de sobrecorrente e Curvas de proteção.',
        'Coordenograma de proteção de sobrecorrente.',
        'Levantamento de dados de equipamentos e impedâncias.',
        'Curva de dano de transformadores.',
        'Cálculo de curto-circuito em cabines de média tensão (MT).',
        'Definição de disjuntor de MT.',
        'Definição de transformador de corrente (TC).',
        'Definição dos ajustes do relé da cabine de MT.',
        'Critérios para coordenação da proteção com a concessionária.',
        'Roteiro para elaboração de projeto de curto-circuito e seletividade da proteção.',
      ],
    },
    {
      name: 'Tópicos Especiais em Proteção de Sistemas Elétricos',
      topics: [
        'Oscilografia e análise de eventos;',
        'PMUs (Phasor Measurement Units);',
        'Norma 61850.',
      ],
    },
  ]

  const [currentCourse, updateCurrentCourse] = useState<Course | undefined>()

  const chunkSize = 3
  let chunkList = []
  for (let i = 0; i < courses.length; i += chunkSize) {
    chunkList.push(courses.slice(i, i + chunkSize))
  }

  const handleClickScroll = (course: Course) => {
    updateCurrentCourse(course)
    // const element = document.getElementById('coursesContent')
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-12">
          <h2 className="mb-2 text-center">
            Especialização em{' '}
            <u className="text-info">
              Proteção de Sistemas Elétricos de Potência
            </u>
          </h2>

          <h4 className="mb-5 text-center">Conteúdo do curso</h4>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              {chunkList.map((list, i) => {
                return (
                  <button
                    key={`carousel-${i}`}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={i}
                    className={(i === 0 ? 'active' : '') + ' bg-primary'}
                    aria-current={i === 0}
                    aria-label={`Slide ${i}`}
                  ></button>
                )
              })}
            </div>
            <div className="carousel-inner">
              {chunkList.map((courses, i) => {
                return (
                  <div
                    key={i}
                    className={`carousel-item ${i === 0 ? 'active' : ''}`}
                  >
                    <div className="row pb-5 mb-2">
                      {courses.map((course, courseI) => {
                        return (
                          <div
                            key={`${i}-${courseI}`}
                            className="col-lg-4 col-md-6 col-12 mb-5 mb-lg-0"
                          >
                            <div className="pricing-thumb bg-white">
                              <div className="pricing-title-wrap">
                                <h6 className="pricing-title text-white mb-0">
                                  {course.name}
                                </h6>
                                <a
                                  className="custom-btn btn-cursos btn mt-3"
                                  // data-bs-toggle="collapse"
                                  href="#coursesContent"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="coursesContent"
                                  onClick={() => handleClickScroll(course)}
                                >
                                  Saiba mais
                                </a>
                              </div>

                              {/* <div className="pricing-body">
                                {course.topics.map((topic, topicI) => {
                                  return topicI < 5 ? (
                                    <p>
                                      <i className="bi-lightning-charge-fill"></i>{' '}
                                      {topic}
                                    </p>
                                  ) : (
                                    <></>
                                  )
                                })}

                                <div className="border-bottom pb-3 mb-4"></div>

                              </div> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="coursesContent">
          {currentCourse && (
            <div className="card card-body">
              <h6 className="mb-4">{currentCourse.name}</h6>

              {currentCourse.topics.map((topic) => {
                return (
                  <p>
                    <i key={topic} className="bi-lightning-charge-fill"></i>{' '}
                    {topic}
                  </p>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
