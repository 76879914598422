import ImgLogo from '../assets/imgLogo';
export function Header() {
  return (
    <div className="navbar navbar-expand-lg">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <a href="#section_1" className="navbar-brand mx-auto mx-lg-0">
          <ImgLogo />
        </a>

        {/* <a className="nav-link custom-btn btn d-lg-none" href="#">
          Buy Tickets
        </a> */}

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">

            <li className="nav-item">
              <a className="nav-link click-scroll" href="#section_2">
                Sobre a Delta
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link click-scroll" href="#section_3">
                Professores
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link click-scroll" href="#section_4">
                Conteúdo
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link click-scroll" href="#section_5">
                Perguntas frequentes
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link click-scroll" href="#section_6">
                Pré-matrícula
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
