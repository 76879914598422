import ImgLogo from '../assets/imgLogo'
/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable jsx-a11y/anchor-has-content */
export function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-12 border-bottom pb-5 mb-5">
            <div className="d-flex">
              <a href="index.html" className="navbar-brand">
                <ImgLogo />
              </a>

              <ul className="social-icon ms-auto">
                <li>
                  <a
                    href="https://www.facebook.com/posdeltasep"
                    target={'_blank'}
                    className="social-icon-link bi-facebook"
                    rel="noreferrer"
                  ></a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/delta.sep/"
                    target={'_blank'}
                    className="social-icon-link bi-instagram"
                    rel="noreferrer"
                  ></a>
                </li>

                <li>
                  <a
                    href="https://api.whatsapp.com/send/?phone=5543991387608&text&type=phone_number&app_absent=0"
                    target={'_blank'}
                    className="social-icon-link bi-whatsapp"
                    rel="noreferrer"
                  ></a>
                </li>

                {/* <li>
                  <a href="#" className="social-icon-link bi-youtube"></a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <ul className="footer-menu d-flex flex-wrap">
              <li className="footer-menu-item">
                <a href="#section_2" className="footer-menu-link">
                  Sobre a Delta
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="#section_3" className="footer-menu-link">
                  Professores
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="#section_4" className="footer-menu-link">
                  Conteúdo
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="#section_5" className="footer-menu-link">
                  Perguntas Frequentes
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="#section_6" className="footer-menu-link">
                  Pré-matrícula
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-6 col-12 ms-lg-auto">
            <div className="copyright-text-wrap d-flex align-items-center">
              <p className="copyright-text ms-lg-auto me-4 mb-0">
                Copyright © 2022 DELTA SEP LTDA.
                <br />
                All Rights Reserved.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
