import ImgUnifil from '../assets/imgUnifil'
export function UnifilBanner() {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-8 col-12">
          <h2 className="text-white mb-4">
            Especialização em parceria com a{' '}
            <a href="https://unifil.br/" target={'_blank'} rel="noreferrer">
              <u className="text-info">UNIFIL</u>
            </a>
          </h2>

          <p className="text-white">
            Desde de 2020 a Delta SEP é parceira da UniFil – Centro
            Universitário Filadélfia na oferta do curso de pós-graduação em
            Proteção de Sistemas Elétricos de Potência. Atualmente conta com
            professores especialistas com Mestrado e Doutorado com vasta
            experiência na área específica, atuantes em meio acadêmico e também
            em empresas geradoras, distribuidoras e transmissoras de energia
            elétrica.
          </p>
        </div>

        <div className="col-lg-2 col-12 ms-lg-auto mt-4 mt-lg-0">
          <a href="https://unifil.br/" target={'_blank'} rel="noreferrer">
            <ImgUnifil />
          </a>
        </div>
      </div>
    </div>
  )
}
