export function MidBanner() {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7 col-12">
          <h2 className="text-white mb-4">
            Seja um <u className="text-info">aluno Delta</u>
          </h2>

          <p className="text-white">
            Há mais de 10 anos formamos profissionais responsáveis e competentes
            em Proteção de Sistemas Elétricos de Potência.
          </p>
        </div>

        <div className="col-lg-3 col-12 ms-lg-auto mt-4 mt-lg-0">
          <a href="#section_6" className="custom-btn btn">
            Faça sua pré-matrícula
          </a>
        </div>
      </div>
    </div>
  )
}
